import { TMarkerLevel } from '../types/map';

export const getMarkerLevel = (zoom: number): TMarkerLevel => {
  
  if (zoom > 11) {
    return 0;
  }
  if (zoom > 9 && zoom <= 11) {
    return 1;
  }
  if (zoom > 7 && zoom <= 9) {
    return 2;
  }
  if (zoom <=7) {
    return 3;
  }

  return 0;
};
