import { ImageList, ImageListItem } from '@mui/material';
import React from 'react';
import { setImageId } from '../../feature/gallerySlice';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { SImageItem, SImageList } from './styles';
import { IImageItemProps } from './types';

export const ImageItem = ({ image, markerId } : IImageItemProps) =>  {

  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.gallery.data[markerId]);
  
  const handleImageClick = () => {
    const index = data.indexOf(image);
    if (data[index + 1]) {
      dispatch(setImageId(data[index + 1].id));
    } else {
      dispatch(setImageId(data[0].id));
    }
  };

  return (
    <ImageList sx={SImageList}>
      <ImageListItem key={image.id} sx={SImageItem} onClick={handleImageClick}>
        <img
          src={`${image.urlImage}?w=500&h=500&fit=crop&auto=format`}
          alt={image.description}
          loading="lazy"
        />
        <div>
          <div>{image.description}</div>
          {image.hashTag?.map((item, i) => 
            <span key={i}>{item} </span>)}
        </div>
      </ImageListItem>
    </ImageList>
  );  
};
