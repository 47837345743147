// import axios from 'axios';
// import { IImageList } from '../types/gallery';
import { dataImages } from './mockImgObj';
// import { SERVER_URL } from './constants';

// eslint-disable-next-line
export const getImages = (id: string) => {
  const data = dataImages;
  return data;
};

// const instance = axios.create({
//   baseURL: SERVER_URL,
// });

// export const getImages = async (id: string): Promise<IImageList> => {
//   const response = await instance.get(SERVER_URL + '/images?M=' + id);
//   if (response.status !== 200) {
//     throw Error(response.data);
//   }
//   return response.data;
// };
