import axios from 'axios';
import { IMarker, TMarkerLevel } from '../types/map';
import { SERVER_URL } from './constants';

const instance = axios.create({
  baseURL: SERVER_URL,
});

export const getMarkers = async (level: TMarkerLevel): Promise<IMarker[]> => {
  const response = await instance.get(SERVER_URL + '/markers?L=' + level);
  if (response.status !== 200) {
    throw Error(response.data);
  }
  return response.data;
};
