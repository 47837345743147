export const SAppIconContainer = {
  display: 'flex',
  width: '100%',
  height: '30px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
  background: 'white',
};

export const SAppIconTitle = {
  display: 'flex',
};

export const SAppIcon = {
  display: 'flex',
  width: '140px',
};
