import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getImages } from '../api/getImages';
import { IGalleryState } from '../types/gallery';

const initialState: IGalleryState = {
  fetch: {
    isLoading: false,
    error: '',
  },
  data: {},
  markerId: null,
  imageId: null,
};

export const getImageList = createAsyncThunk(
  'map/getImageList',
  async (id: string) => {
    const images = await getImages(id);  
    return { id, images };
  },
);
  
export const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setMarkerId(state, action) {
      state.markerId = action.payload;
    },
    setImageId(state, action) {
      state.imageId = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getImageList.pending, state => {
        state.fetch.isLoading = true;
      })
      .addCase(getImageList.fulfilled, (state, action) => {
        state.data = { ...state.data, [action.payload.id]: action.payload.images };
        state.fetch.isLoading = false;
      })
      .addCase(getImageList.rejected, (state, action) => {
        state.fetch.error = action.error.message;
        state.fetch.isLoading = false;
      });
  },
});

export const { setMarkerId, setImageId } = gallerySlice.actions;
export default gallerySlice.reducer;
