import { ImageList, ImageListItem } from '@mui/material';
import React  from 'react';
import { SImageList, SItemChecked, SItemUnChecked } from './styles';
import { IImageGalleryProps } from './types';
import './ImageGallery.css';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { setImageId } from '../../feature/gallerySlice';

export const ImageGallery = ({ data }: IImageGalleryProps) =>  {
  
  const imageId = useAppSelector(state => state.gallery.imageId);

  const dispatch = useAppDispatch();
  const handlerOpenImage = (id:string) => {
    dispatch(setImageId(id));
  };

  return (
    <ImageList sx={{ ...SImageList }} className='ListGallery'>
      <div className='ImageItemsContainer'>
        {data.map((item) => ( 
          <ImageListItem 
            key={item.id} 
            onClick={() => handlerOpenImage(item.id)} 
            sx={(item.id === imageId) ? SItemChecked:SItemUnChecked }>
            <img
              src={`${item.urlImage}?w=164&h=164&fit=crop&auto=format`}
              alt={item.description}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </div>  
    </ImageList>
  );
};
