import React from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { Map }  from './components/Map';
import { Container } from '@mui/material';
import { SContainer } from './styles';
import { ImageGalleryContainer } from './components/ImageGalleryContainer';
import { AppLinksPopupContainer } from './components/AppLinksPopupContainer';
import { useAppSelector, useAppDispatch } from './store/reduxHooks';
import { setIsLoadMap } from './feature/mapSlice';

const App = () => {

  const dispatch = useAppDispatch();

  const markerId = useAppSelector(state => state.gallery.markerId);

  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
  });

  dispatch(setIsLoadMap(isLoaded));
  
  return (
    <Container sx={SContainer} maxWidth={false} disableGutters>
      { isLoaded ? <Map /> : <h2>Loading...</h2>}
      { markerId && <ImageGalleryContainer markerId={markerId}/> }
      { useAppSelector(state => state.map.isLoadMap) && <AppLinksPopupContainer />}
    </Container>
  );
};

export default App;
