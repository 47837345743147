export const SImageItemContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxHeight: '570px', 
  maxWidth: '500px',
  position: 'absolute',
  background: 'white',
  left: '50%',
  bottom: '2px',
  transform: 'translate(-50%, 0)',
  padding: '5px',
};
