export const SCurrentLocationButton = {
  position: 'absolute',
  background: 'white',
  right: '10px',
  bottom: '115px',
};

export const SGpsFixed = {
  color: 'black',
};
