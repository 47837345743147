import { IImage } from '../types/gallery';

export const dataImages: Array<IImage> = [
  {
    id: '1',
    hashTag: ['#breakfast', '#ertyery'],
    urlImage: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    description: 'Breakfast',
  },
  {
    id: '2',
    hashTag: ['#burger', '#jkhgkh'],
    urlImage: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    description: 'Burger',
  },
  {
    id: '3',
    hashTag: ['#camera', '#egdfy'],
    urlImage: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    description: 'Camera',
  },
  {
    id: '4',
    hashTag: ['#coffee', '#erttbyery'],
    urlImage: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    description: 'Coffee',
  },
  {
    id: '5',
    hashTag: ['#hats', '#kljhkhj', '#kljdhfgfhkhj'],
    urlImage: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    description: 'Hats',
  },
  {
    id: '6',
    hashTag: ['#honey', '#ergfddtyery'],
    urlImage: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    description: 'Honey',
  },
  {
    id: '7',
    hashTag: ['#basketball', '#bnbmmmhh'],
    urlImage: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    description: 'Basketball',
  },
  {
    id: '8',
    hashTag: ['#fern', '#fgfjjjjjjjj'],
    urlImage: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    description: 'Fern',
  },
  {
    id: '9',
    hashTag: ['#mushrooms', '#rgteryert'],
    urlImage: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    description: 'Mushrooms',
  },
  {
    id: '10',
    hashTag: ['#tomato', '#gttertre'],
    urlImage: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    description: 'Tomato basil',
  },
  {
    id: '11',
    hashTag: ['#seastar', '#star'],
    urlImage: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    description: 'Sea star',
  },
  {
    id: '12',
    hashTag: ['#bike', '#custom'],
    urlImage: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    description: 'Bike',
  },
];
