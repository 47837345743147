export const SAppLinksPopupContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '510px',
  position: 'absolute',
  right: '10px',
  top: '9px',
  background: 'white',
  border: '1px solid lightgrey',
  borderRadius: '3px',
  padding: '5px',
};
