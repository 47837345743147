import { MarkerF } from '@react-google-maps/api';
import React, { useEffect } from 'react';
import { setImageId, setMarkerId } from '../../feature/gallerySlice';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { getMarkerList } from '../../feature/mapSlice';
import { MarkerData } from '../../share/MarkerSvg';
import { IMapMarkersProps } from './types';

export const MapMarkers = ({ level } : IMapMarkersProps) => {

  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.map.data[level]);
  
  useEffect(() => {
    if ( data.length < 1 ) {
      dispatch(getMarkerList(level));
    }
  }, [level]);     

  const handlerOpenGallery = (id:string) => {
    dispatch(setMarkerId(id));
    dispatch(setImageId(null));
  };
  
  return (
    <>
      {data.map((marker) => 
      {
        const { R, Y, G } = MarkerData({ data: marker });
        return (
          <React.Fragment key={marker.id}>
            { marker.G > 0 && 
              <MarkerF position={{ lng: marker.longitude, lat: marker.latitude }} icon={{
                path: G.path,
                fillColor: G.fill,
                fillOpacity: 0.9,
                scale: 1,
                strokeColor: G.fill,
                strokeWeight: 1,
              }}
              onClick={() => handlerOpenGallery(marker.id)}/>
            }
            { marker.Y > 0 && 
              <MarkerF position={{ lng: marker.longitude, lat: marker.latitude }} icon={{
                path: Y.path,
                fillColor: Y.fill,
                fillOpacity: 0.9,
                scale: 1,
                strokeColor: Y.fill,
                strokeWeight: 1,
              }}
              onClick={() => handlerOpenGallery(marker.id)}/>
            }
            { marker.R > 0 && 
              <MarkerF position={{ lng: marker.longitude, lat: marker.latitude }} icon={{
                path: R.path,
                fillColor: R.fill,
                fillOpacity: 0.9,
                scale: 1,
                strokeColor: R.fill,
                strokeWeight: 1,
              }}
              onClick={() => handlerOpenGallery(marker.id)}/>
            }
          </React.Fragment>
        );
      }
      )}
    </>
  );
};
