import { IconButton } from '@mui/material';
import { GpsFixed } from '@mui/icons-material';
import React from 'react';
import { SCurrentLocationButton, SGpsFixed } from './styles';
import { ICurrentLocationButtonProps } from './types';

export const CurrentLocationButton = ({ setToCurrentGeoposition } : ICurrentLocationButtonProps) =>  {

  return (
    <IconButton sx={SCurrentLocationButton} onClick={setToCurrentGeoposition} size='medium'>
      <GpsFixed sx={SGpsFixed}/>
    </IconButton>
  );  
};
