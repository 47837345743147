import { GoogleMap } from '@react-google-maps/api';
import React, { useState } from 'react';  
import { setImageId, setMarkerId } from '../../feature/gallerySlice';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { TMarkerLevel } from '../../types/map';
import { ImageItemContainer } from '../ImageItemContainer';
import { CurrentLocationButton } from '../CurrentLocationButton';
import { MapMarkers } from '../MapMarkers';
import config from './config';
import { useMapCenterStorage } from '../../hooks';
import { getMarkerLevel } from '../../helpers';

export const  Map = () => {

  const dispatch = useAppDispatch();

  const [map, setMap] = useState<google.maps.Map | undefined> (undefined);
  const [zoom, setZoom] = useState<number> (config.defaultZoom);
  const [level, setLevel] = useState<TMarkerLevel> (config.defaultLevel);
  const { center, setCookiePosition } = useMapCenterStorage();

  const markerId = useAppSelector(state => state.gallery.markerId);
  const imageId = useAppSelector(state => state.gallery.imageId);
  const geoposition = useAppSelector(state => state.map.geoposition);
  
  const closeGallery = () => {
    dispatch(setMarkerId(null));
    dispatch(setImageId(null));
  };

  const setToCurrentGeoposition = () => {
    if (geoposition !== undefined) {
      map?.setCenter(geoposition);
    }
    setZoom(config.defaultZoom);
  };
  
  const handleZoomChanged = () => {
    setLevel(getMarkerLevel(map ? map.getZoom() || config.defaultZoom : config.defaultZoom));
  };

  return (
    <GoogleMap
      mapContainerStyle={config.containerStyle}
      options={config.googleMapOptions}
      center={center}
      zoom={zoom}
      onLoad={m => { 
        setMap(m); 
      }}
      onZoomChanged={handleZoomChanged}
      onClick={closeGallery}
      onCenterChanged={() => setCookiePosition(map?.getCenter())}
    >
      <MapMarkers level ={level} />
      { map && navigator.geolocation && <CurrentLocationButton setToCurrentGeoposition={setToCurrentGeoposition}/> }
      { map && markerId && imageId && <ImageItemContainer markerId={markerId} imageId={imageId} /> }
    </GoogleMap>   
  );
};
