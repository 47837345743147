import { Container } from '@mui/material';
import React, { useEffect } from 'react';
import { ImageGallery } from '../ImageGallery';
import { SImageGalleryContainer } from './styles';
import { IImageGalleryContainerProps } from './types';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { getImageList } from '../../feature/gallerySlice';

export const ImageGalleryContainer = ({ markerId }: IImageGalleryContainerProps) =>  {
  
  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.gallery.data);
  
  useEffect(() => {
    if (Object.keys(data).length === 0) {
      dispatch(getImageList(markerId));
    } else {
      if (!Object.keys(data).includes(markerId)) {
        dispatch(getImageList(markerId));
      }
    }
  }, [markerId]);  
 
  return (
    <Container sx={SImageGalleryContainer} maxWidth={false} disableGutters>
      { data[markerId]?.length && <ImageGallery data={data[markerId]}/> }
    </Container>
  );
};
