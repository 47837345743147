import { TMarkerLevel } from '../../types/map';

export default {
  defaultZoom: 12,
  defaultLevel: 0 as TMarkerLevel,
  centerMap: {
    lat: 50.45466,
    lng: 30.5238
  },
  containerStyle: {
    height: '100%', 
    width: '100%',
  },
  googleMapOptions : {
    fullscreenControl: false,
    streetViewControl: false,
  }
};
