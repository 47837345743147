export const SImageList = {
  height: '164px', 
  width: '99vw',
  minHeight: '182px',
  display: 'flex',
  justifyContent: 'center',
  overflowX: 'auto',
  margin: '1px',
  background: 'white',
};

export const SItemChecked = {
  minWidth: '150px',
  maxHeight: '150px',
  margin: '14px 8px',
  opacity: '0.7',
};

export const SItemUnChecked = {
  minWidth: '164px',
  maxHeight: '164px',
  margin: '1px',
};
