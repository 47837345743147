import { Container } from '@mui/material';
import React from 'react';
import qrcode from './assets/qrcode.jpeg';
import { SAppQr, SAppQrContainer } from './styles';

export const AppQrContainer = () =>  {

  return (
    <Container sx={SAppQrContainer} maxWidth={false} disableGutters>
      <Container sx={SAppQr} maxWidth={false} disableGutters>
        <img src={qrcode} loading='lazy' height={'170px'} width={'170px'}/>
      </Container>  
    </Container>
  ); 
};
