import { Collapse, Container, Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AppIconContainer } from '../AppIconContainer';
import { AppQrContainer } from '../AppQrContainer';
import { SAppLinksPopupContainer } from './styles';

export const AppLinksPopupContainer = () =>  {

  const [showPopup, setShowPopup] = useState(false);
  const [hideQr, setHideQr] = useState(false);
  const [collapseTimeout, setCollapseTimeout] = useState(0);

  useEffect(() => {
    setTimeout(() => setShowPopup(true), 3000);
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('hideQr') === 'true') {
      return;
    } else {
      setHideQr(true);
      setTimeout(() => {
        sessionStorage.setItem('hideQr', 'true');
        setCollapseTimeout(1000);
        setHideQr(false);
      }, 10000);
    }
  }, [hideQr]);

  const handleShow = () => {
    setHideQr(true);
    sessionStorage.setItem('hideQr', 'false');
  };

  return (
    <Slide 
      in={showPopup}
      direction='down'
      timeout={2000}
      mountOnEnter 
      unmountOnExit>
      <Container 
        sx={SAppLinksPopupContainer} 
        maxWidth={false} 
        disableGutters>
        <Collapse 
          in={hideQr} 
          timeout={collapseTimeout}>
          <AppQrContainer />
        </Collapse>
        <Container onClick={handleShow} maxWidth={false} disableGutters>
          <AppIconContainer/>
        </Container>
      </Container>
    </Slide>
  ); 
};
