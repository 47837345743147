import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getMarkers } from '../api/getMarkers';
import { IMapState, TMarkerLevel } from '../types/map';

const initialState: IMapState = {
  fetch: {
    isLoading: false,
    error: '',
  },
  data: {
    0: [],
    1: [],
    2: [],
    3: [],
  },
  isLoadMap: false,
  geoposition: undefined
};

export const getMarkerList = createAsyncThunk(
  'map/getMarkerList',
  async (level: TMarkerLevel) => {
    const markers = await getMarkers(level);
    return { level, markers };
  },
);

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setIsLoadMap(state, action) {
      state.isLoadMap = action.payload;
    },
    setGeoposition(state, action) {
      state.geoposition = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getMarkerList.pending, state => {
        state.fetch.isLoading = true;
      })
      .addCase(getMarkerList.fulfilled, (state, action) => {
        state.data = { ...state.data, [`${action.payload.level}`] : action.payload.markers };
        state.fetch.isLoading = false;
      })
      .addCase(getMarkerList.rejected, (state, action) => {
        state.fetch.error = action.error.message;
        state.fetch.isLoading = false;
      });
  },
});

export const { setIsLoadMap, setGeoposition } = mapSlice.actions;
export default mapSlice.reducer;
