import { Container, Typography } from '@mui/material';
import React from 'react';
import { SAppIcon, SAppIconContainer, SAppIconTitle } from './styles';
import appStore from './assets/appstore.png';
import googlePlay from './assets/googleplay.png';

export const AppIconContainer = () =>  {
  return (

    <Container sx={SAppIconContainer} maxWidth={false} disableGutters>
      <Container sx={SAppIconTitle} maxWidth={false} disableGutters>
        <Typography variant='h6'>Fast when using the mobile app</Typography>
      </Container>
      <Container sx={SAppIcon} maxWidth={false} disableGutters> 
        <img src={appStore} loading='lazy' height={'30px'} width={'100px'}/>
      </Container>
      <Container sx={SAppIcon} maxWidth={false} disableGutters>
        <img src={googlePlay} loading='lazy' height={'30px'} width={'100px'}/>
      </Container>
    </Container>
  ); 
};
