export const SAppQrContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '400px',
  padding: '5px',
};

export const SAppQr = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
};
