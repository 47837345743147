import { useState } from 'react';
import config from '../components/Map/config';
import { setGeoposition } from '../feature/mapSlice';
import { getCookie, setCookie } from '../helpers';
import { useAppDispatch } from '../store/reduxHooks';

export const useMapCenterStorage = () => {

  const dispatch = useAppDispatch();

  const [center, setCenter] = useState<google.maps.LatLng | google.maps.LatLngLiteral | undefined> (() => {
  
    const cookie = getCookie();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        dispatch(setGeoposition({ lat: position.coords.latitude, lng: position.coords.longitude }));
      });
    }

    if(cookie) {
      return cookie;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
      return;
    }

    return config.centerMap;
  });

  const setCookiePosition = (location: google.maps.LatLng | google.maps.LatLngLiteral | undefined) => {
    setCookie(location); 
  };

  return { center, setCookiePosition };
};
