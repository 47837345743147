export const SImageList = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  margin: '0px',
};

export const SImageItem = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%', 
  width: '100%',
};
