import { Container } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../store/reduxHooks';
import { ImageItem } from '../ImageItem/ImageItem';
import { SImageItemContainer } from './styles';
import { IImageItemContainerProps } from './types';

export const ImageItemContainer = ({ markerId, imageId } : IImageItemContainerProps) =>  {

  const data = useAppSelector(state => state.gallery.data[markerId]);
  
  return (
    <Container sx={SImageItemContainer} maxWidth={false} disableGutters>
      {data.map((image) => ( 
        (image.id === imageId) && <ImageItem key={image.id} image={image} markerId={markerId}/>
      ))}
    </Container>
  ); 
};
